import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {Observer} from "gsap/Observer";
import Cursor from "./cursor";

gsap.registerPlugin(SplitText, ScrollTrigger, Observer);

// Custom cursor
new Cursor(document.querySelector('.cursor'))

const mq = window.matchMedia('(max-width: 840px)');

const mainClass = document.querySelector('main'),
    heroTagline = document.querySelector('.hero__tagline'),
    heroText = document.querySelector('.intro__hero'),
    heroWords = document.querySelectorAll('.words'),
    header = document.querySelector('.header'),
    headerLogo = document.querySelector('.calvostudio__logo'),
    reelContainer = document.querySelector('.reel__container'),
    allProjects = gsap.utils.toArray('.project__item'),
    allServices = gsap.utils.toArray('.service__item'),
    largeImage = document.querySelector('.projects__image'),
    lInside = document.querySelector('.projects__image .image_inside'),
    body = document.querySelector('body'),
    splitHeroTagline = new SplitText(heroTagline, {
        type: "lines, chars, words",
        charsClass: "chars",
        linesClass: "lines",
        wordsClass: 'words'
    }),
    splitHeroText = new SplitText(heroText, {
        type: "lines, words",
        linesClass: "lines",
        wordsClass: 'words'
    });

var video = document.querySelectorAll('video') //get all vids
var projectOffsite = true;
var toHome = false;
const tlanim = gsap.timeline();


// HOME PAGE

function revealText() {

    // if (mq.matches) {
    //     const splitHeroText = new SplitText(heroText, {
    //         type: "lines",
    //         linesClass: "lines"
    //     });
    // }
    tlanim.set(heroTagline, {opacity: 1})
        .set(heroText, {opacity: 1})
        .set(header, {opacity: 0})
        .set(splitHeroTagline.words, {y: '150%', opacity: 0, ease: 'power3.in', rotation: 2})
        .set(splitHeroText.lines, {y: '250%', opacity: 0, ease: 'power3.in'})
        .to(splitHeroTagline.words, {
            y: '0%',
            ease: 'power1.out',
            stagger: 0.05,
            opacity: 1,
            rotation: 0,
            duration: 1,
            delay: 0.25
        })

        .to(splitHeroTagline.words, {
            ease: 'power1.in',
            // stagger: 0.075,
            stagger: { // wrap advanced options in an object
                each: 0.075,
                from: "random",
                grid: "auto",
                ease: "power2.inOut",
            },
            opacity: 0,
            duration: 0.75,
            onComplete: function() {
                body.classList.remove('init-animation');
            }
        }, '=+0.25')
        .addLabel("initContent")
        .to(splitHeroText.lines, {
            y: '0%',
            ease: 'power1.out',
            stagger: 0.125,
            opacity: 1,
            duration: 1.25
        }, 'initContent')
        .to(header, {opacity: 1, duration: 2.5}, 'initContent')
        .to(heroText, {opacity: 1})

}

function logoReveal() {
    const tl = gsap.timeline()
    tl.to(headerLogo, {
        y: '0%',
        ease: 'Power1.easeOut',
        scrollTrigger: {
            trigger: heroText,
            scrub: true,
            start: "bottom top",
        },
    })
}

function scrollReel() {
    if (!mq.matches) {
        const tl = gsap.timeline()
        tl.to(reelContainer, {
            scrollTrigger: {
                trigger: reelContainer,
                scrub: true,
                start: "top 65%",
                end: "bottom top",
                toggleClass: "reel--scrolled",
                duration: 0.5,
            }
        })
    }
}

function instaBrowser(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var str = navigator.userAgent;
    var instagram = str.indexOf("Instagram");
    var facebook = str.indexOf("FB");

    if (/android/i.test(userAgent) && (instagram != -1 || facebook != -1) ) {
        document.write("<a target=\"_blank\" href=\"https://yourdomain.com\" download id=\"open-browser-url\">Please wait. Proceed to Chrome</a>");
        window.stop();
        let input = document.getElementById('open-browser-url');
        if (input) {
            input.click();
        }
    }
}
// END HOME

// Project Page
function scrollGalleryProjectSide() {
    const tl = gsap.timeline()
    tl.to('.column--side', {
        scrollTrigger: {
            trigger: '.gallery',
            scrub: true,
            start: "top bottom",
            end: "bottom top",
        },
        y: '20%',
        ease: 'power1.in',
    })
}

function scrollGalleryProjectCenter() {
    const tl = gsap.timeline()
    tl.to('.column--center', {
        scrollTrigger: {
            trigger: '.gallery',
            scrub: true,
            start: "top bottom",
            end: "bottom top",
        },
        y: '-10%',
        ease: 'power1.in',
    })
}

function imgReveal() {
    let projectImg = gsap.utils.toArray('.project--img');
    projectImg.forEach((container) => {
        let image = container.querySelector("img");
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: container,
                toggleActions: "restart none none reset"
            }
        });

        tl.set(container, {
            autoAlpha: 0,
            yPercent: 10,
        });
        tl.to(container, 2, {
            autoAlpha: 1,
            yPercent: 0,
        });
    });
}

function projectTitleReveal() {
    let tl = gsap.timeline();
    const splitProjectText = new SplitText('.project--text', {
        type: "lines",
        linesClass: "project--lines",
        wordsClass: 'project--words'
    });

    tl.set('.project--lines', {y: '100%'})
        .to('.project--lines', {
            y: '0',
            duration: 0.75
        })
}

// END PROJECT PAGE

// ABOUT US PAGE

function revealAbout() {
    const heroabout = document.querySelector('.aboutus__content--title'),
        splitHeroAbout = new SplitText(heroabout, {
            type: "lines, words",
            linesClass: "lines",
            wordsClass: 'words'
        }),
        tlabout = gsap.timeline();

    tlabout.set(splitHeroAbout.lines, {y: '250%', opacity: 0, ease: 'power3.in'})
        // .set(splitHeroAbout.words, {rotation: 2})
        .addLabel("initReveal")
        .to(splitHeroAbout.lines, {
            y: '0%',
            ease: 'power1.out',
            stagger: 0.15,
            opacity: 1,
            duration: 1.05
        }, 'initReveal')
    // .to(splitHeroAbout.words, {
    //     rotation: 0,
    //     stagger: 0.1,
    //     ease: 'power4.out',
    // }, 'initReveal')

}

function aboutTextReveal() {
    const aboutText = document.querySelectorAll('.aboutus__content--paragraph'),
        splitTextAbout = new SplitText(aboutText, {
            type: "lines, words",
            linesClass: "lines",
            wordsClass: 'words'
        }),
        tltext = gsap.timeline();
    splitTextAbout.lines.forEach(line => {
        tltext.to(line, {
            scrollTrigger: {
                trigger: line,
                scrub: true,
                // markers: true,
                start: "top center+=20%",
                end: "bottom center-=25%",
                toggleClass: "about--scrolled",
            },
        })

    })
}

function aboutPicture() {
    const pictureAbout = document.querySelector('.aboutus__content--picture'),
        headerAbout = document.querySelector('.aboutus__content--header'),
        tlimg = gsap.timeline();
    tlimg.to(pictureAbout, {
        scrollTrigger: {
            trigger: pictureAbout,
            scrub: true,
            // markers: true,
            pin: true,
            start: "center center+=5%",
            end: "bottom top",
        },
    })
}

// END ABOUT US

// NAVIGATION

function hideMenu() {
    const headerMenu = document.querySelector('.header__menu');

    Observer.create({
        target: window,         // can be any element (selector text is fine)
        type: "wheel,touch",    // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
        onUp: () => {
            headerMenu.classList.remove('header-scroll');
        },
        onDown: () => {
            headerMenu.classList.add('header-scroll');
        }
    });
}

// END NAVIGATION

window.addEventListener("load", function (e) {
    if (mainClass.classList.contains('home')) {
        revealText();
        videoSize();
        logoReveal();
        scrollReel();
        if (!mq.matches) {
            overWords();
            logoReveal();
        }
    }
    if (mainClass.classList.contains('project')) {
        // imgReveal()
        projectTitleReveal()
        scrollGalleryProjectSide()
        scrollGalleryProjectCenter()
        videoSize()
    }
    // if (mainClass.classList.contains('projects') && (!mq.matches)) {
    //     initPortfolioHover(allProjects)
    // }

    // if (mainClass.classList.contains('service') && (!mq.matches)) {
    //     initPortfolioHover(allServices)
    // }

    if (mainClass.classList.contains('aboutus')) {
        revealAbout()
        if (!mq.matches) {
            aboutTextReveal()
        }
        // aboutPicture()
    }
    hideMenu()
})

// Animation over text on Hero section
function overWords() {

    splitHeroText.words.forEach(function (heroWord) {
        heroWord.addEventListener("mouseenter", (event) => {
            // if (heroWord.classList.contains('over_words')) {
            //     heroWord.classList.remove('over_words');
            // } else {
            //     heroWord.classList.add('over_words');
            // }
            heroWord.classList.add('over_words');
        });
    })
    heroText.addEventListener("mouseenter", (event) => {
        heroText.classList.add('over_hero');
    })
}

// Animation over projects

function initPortfolioHover(selectors) {
    selectors.forEach(link => {
        link.addEventListener('mouseenter', createPortfolioHover);
        link.addEventListener('mouseleave', createPortfolioHover);
        link.addEventListener('mousemove', createPortfolioMove);
    });
}

function imageGalleryPostion() {
    const imageWidth = largeImage.offsetWidth
    const imageChange = Math.floor(Math.random() * 2)
    if (imageChange % 2 == 0) {
        return '5vw'
    } else {
        return '-5vw'
    }
}

function createPortfolioHover(e) {

    if (e.type === 'mouseenter') {
        const {imageproject} = e.target.dataset;
        var projectType = e.target.classList[1];
        const projectPosition = e.y;
        const tl = gsap.timeline();
        if (projectType === 'storie') {
            var xWidth = '27rem';
            var yHeight = '48rem';
        } else if (projectType === 'motion') {
            var xWidth = '30rem';
            var yHeight = '30rem';
        } else {
            var xWidth = '55rem';
            var yHeight = '37.5rem';
        }
        tl
            .set(lInside, {backgroundImage: `url(${imageproject})`})
            .set(largeImage, {yPercent: 60, x: imageGalleryPostion(), autoAlpha: 0, width: xWidth, height: yHeight})
            .to(largeImage, {autoAlpha: 1, yPercent: 20, duration: 1})
            .to(e.target, {autoAlpha: 1}, 0);

    } else if (e.type === 'mouseleave') {

        const tl = gsap.timeline();
        tl
            .to(largeImage, {autoAlpha: 0, duration: 1})
    }

}

function createPortfolioMove(e) {

    const {clientY} = e;

    // move large image
    gsap.to(largeImage, {
        duration: 1.2,
        y: getPortfolioOffset(clientY) / 6,
        ease: 'power3.out'
    });

}

function getPortfolioOffset(clientY) {
    return -(document.querySelector('.project__list').clientHeight - clientY);
}


/* VIDEO*/

//add source to video tag
function addSourceToVideo(element, src) {
    var source = document.createElement('source');
    source.src = src;
    source.type = 'video/mp4';
    element.appendChild(source);
}

//determine screen size and select mobile or desktop vid
function whichSizeVideo(element, src) {
    var windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
    if (windowWidth > 1200) {
        addSourceToVideo(element, src.dataset.desktopVid);
    } else {
        addSourceToVideo(element, src.dataset.mobileVid);
    }
}

// init only if page has videos
function videoSize() {
    if (video !== undefined) {
        video.forEach(function (element, index) {
            whichSizeVideo(
                element, //element
                element  //src locations
            );
        });
    }
}


var playButton = document.querySelectorAll(".player--btn");
// Event listener for the play/pause button
video.forEach((playVideo, index) => {
    video[index].addEventListener("click", function () {
        // console.log('clicked')
        if (video[index].paused == true) {
            // Play the video
            video[index].play();
            playButton[index].classList.toggle('hidden');
        } else {
            // Pause the video
            video[index].pause();
            playButton[index].classList.toggle('hidden');
        }
    });
    playButton[index].addEventListener("click", function () {
        // console.log('clicked')
        if (video[index].paused == true) {
            // Play the video
            video[index].play();
            playButton[index].classList.toggle('hidden');
        } else {
            // Pause the video
            video[index].pause();
            playButton[index].classList.toggle('hidden');
        }
    });
})


/* Services */

// const btnServices = document.querySelectorAll('.btn--info'),
// itemService = document.querySelectorAll('.service__item');
//
// btnServices.forEach(( btnItem, index) => {
//     btnServices[index].addEventListener("click", function () {
//         itemService[index].classList.toggle('service--open')
//     })
// })
//
// itemService.forEach(( btnItem, index) => {
//     itemService[index].addEventListener("click", function () {
//         itemService[index].classList.toggle('service--open')
//     })
// })

import { gsap } from 'gsap';
import { lerp, getMousePos } from './utils';

// Track the mouse position
let mouse = {x: 0, y: 0};
window.addEventListener('mousemove', ev => mouse = getMousePos(ev));

// const allurl = document.querySelectorAll('a');
const allitems = document.querySelectorAll(['.service__item', '.project__item', 'a', 'footer', '.contact__page', 'video']);
const footerLinks = document.querySelectorAll(['.footer__menu--link', 'footer .contact--link']);

allitems.forEach(link =>{
    link.addEventListener('mouseenter', () =>{
        document.querySelector('.cursor__inner').setAttribute('r', '17')
        document.querySelector('.cursor__inner').style.fill = 'hsla(142,42%,68%,1)'
        document.querySelector('.cursor__inner').style.opacity = '0.9'
    });
    link.addEventListener('mouseleave', () =>{
        document.querySelector('.cursor__inner').setAttribute('r', '20')
        document.querySelector('.cursor__inner').style.fill = '#000'
        document.querySelector('.cursor__inner').style.opacity = '0.8'
    });
})
footerLinks.forEach(footerLink =>{
    footerLink.addEventListener('mouseleave', () =>{
        console.log('out');
        document.querySelector('.cursor__inner').setAttribute('r', '17')
        document.querySelector('.cursor__inner').style.fill = 'hsla(142,42%,68%,1)'
        document.querySelector('.cursor__inner').style.opacity = '0.9'
    });
})


export default class Cursor {
    constructor(el) {
        this.DOM = {el: el};
        this.DOM.el.style.opacity = 0;

        this.bounds = this.DOM.el.getBoundingClientRect();

        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.18},
            ty: {previous: 0, current: 0, amt: 0.18}
        };

        this.onMouseMoveEv = () => {
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width/2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds.height/2;
            gsap.to(this.DOM.el, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});
            requestAnimationFrame(() => this.render());
            window.removeEventListener('mousemove', this.onMouseMoveEv);
        };
        window.addEventListener('mousemove', this.onMouseMoveEv);
    }
    render() {
        this.renderedStyles['tx'].current = mouse.x - this.bounds.width/2;
        this.renderedStyles['ty'].current = mouse.y - this.bounds.height/2;

        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }

        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px)`;

        requestAnimationFrame(() => this.render());
    }
}

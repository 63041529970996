import './scss/main.scss'
import './js/animations.js'

const screenHeight = () => {
    var vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const mq = window.matchMedia('(max-width: 840px)');
const body = document.querySelector('body');
const header = document.querySelector('.header');
const headerBtn = document.querySelector('.header__btn');
const contactAside = document.querySelector('.contact')
const contactBtn = document.querySelector('.header__menu--contact')
const contactClose = document.querySelector('.contact__button--close')
const langItem = document.querySelector('.menu__link--lang')

function init() {
    screenHeight();
    cookiesInit()
    contactOpen();
}

window.onload = (event) => {
    init();
};

window.addEventListener('resize', screenHeight())


function contactOpen() {

    // contactBtn.addEventListener('click', () => {
    //     contactAside.classList.toggle('contact--open');
    //     header.classList.toggle('contact--open');
    // })

    headerBtn.addEventListener('click', () => {
        contactAside.classList.toggle('contact--open');
        header.classList.toggle('contact--open');
        body.classList.toggle('contact--open');
    })

    contactClose.addEventListener('click', () => {
        contactAside.classList.toggle('contact--open');
        header.classList.toggle('contact--open');
        body.classList.toggle('contact--open');
    })
}


/* COOKIES BANNER*/

let timeout;

function cookiesInit() {
    timeout = setTimeout(cookiesReveal, 4000);
}

function cookiesReveal() {
    const cookiesBanner = document.getElementById('glowCookies-banner');
    const parentDiv = cookiesBanner.parentNode;
    cookiesBanner.classList.add('active')
}

